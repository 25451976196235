<template>
  <div class="chart-container">
    <div>
      <div class="flex items-center justify-between mb-[16px]">
        <p>統計銷售前 10 的商品銷售數量</p>
        <ElInputWrapper style="width: 148px">
          <BaseElSelect v-model="selectYear" @change="onYearChange">
            <BaseElSelectOption
              v-for="option in yearOptions"
              :key="option.value"
              :value="option.value"
              :label="option.label"
            />
          </BaseElSelect>
        </ElInputWrapper>
      </div>

      <div class="flex justify-end">
        <BaseElRadioGroup v-model="selectView" size="small" @change="onViewChange">
          <el-radio-button label="week">週檢視</el-radio-button>
          <el-radio-button label="month">月檢視</el-radio-button>
        </BaseElRadioGroup>
      </div>
    </div>
    <div class="divider" />
    <div ref="chartRef" class="chart" />
  </div>
</template>

<script>
import { computed, defineComponent, ref, watch } from 'vue'
import { useChart } from '@/use/dashboard'
import ElInputWrapper from '@/components/ElInputWrapper.vue'
import { useVModel } from '@vueuse/core'
import { formatDate } from '@/utils/date'
import { get, map } from 'lodash'

export default defineComponent({
  name: 'BeautyAISalesProductAnalysisChart',
  components: { ElInputWrapper },
  props: {
    year: {
      type: Number,
    },
    view: {
      type: String,
      default: 'month',
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    yearOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup (props, { emit }) {
    const selectYear = useVModel(props, 'year', emit)
    const selectView = useVModel(props, 'view', emit)
    const { chartRef, updateChart } = useChart({
      series: [],
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      legend: {
        position: 'top',
        showForSingleSeries: true,
        markers: {
          width: 12,
          height: 12,
          radius: 0,
          strokeWidth: 0,
        },
      },
      xaxis: {
        categories: [],
      },
      yaxis: [{
        title: {
          text: '銷售量',
        },
      }],
    })
    const getChart = () => {}

    const onYearChange = (year) => {
      emit('yearChange', year)
    }
    const onViewChange = (view) => {
      emit('viewChange', view)
    }

    const displayChartData = computed(() => {
      const chartData = get(props, 'data.result', [])
      // 將 data 依照 date 進行排序
      const sortData = chartData.sort((a, b) => new Date(a.date) - new Date(b.date))
      const dates = sortData.map(item => formatDate(item.date, 'YYYY/MM/DD'))
      // dates 排除重複的日期
      const uniqueDates = [...new Set(dates)]

      // seriesData , 依照 name 進行分類，並將 totalItems 組成 data，如果沒有 totalItems 則填入 0
      // data 陣列的長度為 uniqueDates
      // {name: 'xxx', data: [1, ,2, 3, 4, 0]}
      const seriesData = sortData.reduce((acc, item) => {
        const index = uniqueDates.findIndex(date => date === formatDate(item.date, 'YYYY/MM/DD'))
        const target = acc.find(data => data.name === item.name)
        if (target) {
          target.data[index] = item.totalItems
        } else {
          const data = new Array(uniqueDates.length).fill(0)
          data[index] = item.totalItems
          acc.push({ name: item.name, data })
        }
        return acc
      }, [])

      return {
        series: seriesData,
        labels: uniqueDates,
      }
    })

    watch(displayChartData, (newData) => {
      updateChart({
        series: newData.series,
        xaxis: {
          categories: newData.labels,
        },
      })
    })

    return { getChart, chartRef, selectYear, onYearChange, onViewChange, selectView, displayChartData }
  },
})
</script>

<style lang="postcss" scoped>
.chart-container {
    @apply bg-white rounded-[8px] p-[20px];
}

.divider {
  @apply w-full h-[2px] bg-[#ECECEC] my-[16px];
}
</style>
